import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from "@angular/core";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.url.startsWith(sessionStorage.getItem("baseUrl"))) {
            var accesstoken = sessionStorage.getItem("access_token");
            // Clone the request to add the new header
            req = req.clone({
                setHeaders: {
                    // This is where you can use your various tokens
                    Authorization: `Bearer ${accesstoken}`
                }
            });

            // Pass the cloned request instead of the original request to the next handle
            return next.handle(req);
        }

        return next.handle(req);
    }
}