import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class TaxModelService {
    func_host = sessionStorage.getItem('baseUrl');

    constructor(private http: HttpClient) { }

    private getLoginUrl(servicingType: string) {
        return this.http.get(
            `${this.func_host}api/create-taxmodel-login-url/${servicingType}`
        );
    }

    public navigateToTaxModelService(serviceType: string) {
        this.getLoginUrl(serviceType).subscribe((response: any) => {
            window.open(response.url, '_blank');
        });
    }
}
