import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ServicesprofileService {
    private permissions: Array<string>;

    public randomUnavailableServices = ['TaxBenchmark', 'TaxProof', 'VATCheck', 'ADCheck', 'PhishingAwareness'];

    public totalTaxServices: number = 3;
    public taxServicesForUser: number;

    public totalITServices: number = 2;
    public itServicesForUser: number;

    constructor() {
        var storedPermissions = sessionStorage.getItem("permissions");
        this.permissions = storedPermissions ? storedPermissions.split(' ') : [];

        this.taxServicesForUser = 0;
        if (this.canAccessTaxBenchmark()) {
            this.taxServicesForUser += 1;
            this.randomUnavailableServices.splice(this.randomUnavailableServices.indexOf('TaxBenchmark'), 1);
        }
        if (this.canAccessTaxProof()) {
            this.taxServicesForUser += 1;
            this.randomUnavailableServices.splice(this.randomUnavailableServices.indexOf('TaxProof'), 1);
        }
        if (this.canAccessVATCheck()) {
            this.taxServicesForUser += 1;
            this.randomUnavailableServices.splice(this.randomUnavailableServices.indexOf('VATCheck'), 1);
        }

        this.itServicesForUser = 0;
        if (this.canAccessADCheck()) {
            this.itServicesForUser += 1;
            this.randomUnavailableServices.splice(this.randomUnavailableServices.indexOf('ADCheck'), 1);
        }
        if (this.canAccessPhishingAwareness()) {
            this.itServicesForUser += 1;
            this.randomUnavailableServices.splice(this.randomUnavailableServices.indexOf('PhishingAwareness'), 1);
        }

        this.randomUnavailableServices = this.shuffle(this.randomUnavailableServices).splice(0, 3);
    }

    public hasClaim(claim: string): boolean {
        return this.permissions && this.permissions.indexOf(claim) > -1;
    }

    /* Tax-related services */
    public canAccessVATCheck(): boolean {
        return this.hasClaim("vat-check");
    }

    public canCreateVATCheck(): boolean {
        return this.hasClaim("vat-check");
    }

    public canAccessTaxProof(): boolean {
        return this.hasClaim("tax-proof");
    }

    public canAccessTaxBenchmark(): boolean {
        return this.hasClaim("tax-benchmark");
    }
    /* End of tax-related services */

    /* IT-related services */
    public canAccessADCheck(): boolean {
        return this.hasClaim("ad-check");
    }

    public canAccessPhishingAwareness(): boolean {
        return this.hasClaim("phishing-awareness");
    }
    /* End of IT-related services */

    private shuffle(array) {
        var currentIndex = array.length, temporaryValue, randomIndex;

        while (0 !== currentIndex) {
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;

            temporaryValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = temporaryValue;
        }

        return array;
    }
}
