import { NgModule } from '@angular/core';
import { ThemeComponent } from './theme.component';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../auth/auth.guard';

const routes: Routes = [
    {
        "path": "",
        "component": ThemeComponent,
        "canActivate": [AuthGuard],
        "children": [
            {
                "path": "dashboard",
                "loadChildren": () => import('./pages/default/dashboard/dashboard.module').then(m => m.DashboardModule),
                "data": { requiredClaims: ["read:vat_check"] }
            },
            {
                "path": "overview",
                "loadChildren": () => import('./pages/default/overview/overview.module').then(m => m.OverviewModule)
            },
            {
                "path": "rapportages",
                "loadChildren": () => import('./pages/default/report/report.module').then(m => m.ReportModule)
            },
            {
                "path": "404",
                "loadChildren": () => import('./pages/default/not-found/not-found.module').then(m => m.NotFoundModule)
            },
            {
                "path": "services",
                "loadChildren": () => import('./pages/default/services/services.module').then(m => m.ServicesModule)
            },
            {
                "path": "",
                "redirectTo": "overview",
                "pathMatch": "full"
            }
        ]
    },
    {
        "path": "overview",
        "loadChildren": () => import('./pages/default/overview/overview.module').then(m => m.OverviewModule),
        "canActivate": [AuthGuard]
    },
    {
        "path": "identity-callback",
        "loadChildren": () => import('../identity-callback/identity-callback.module').then(m => m.IdentityCallbackModule)
    },
    {
        "path": "**",
        "redirectTo": "404",
        "pathMatch": "full"
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class ThemeRoutingModule { }