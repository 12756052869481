
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivationStart, ActivationEnd } from '@angular/router';
import { AuthService } from './auth.service';
import { ServicesprofileService } from "../servicesprofile.service";
import { filter } from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {
    routeData: any;
    returnUrl: any;
    constructor(
        public auth: AuthService,
        public router: Router,
        public services: ServicesprofileService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (!this.auth.isAuthenticated()) {
            sessionStorage.setItem('redirect', state.url.toLowerCase())
            this.auth.login();
            return false;
        }

        this.services = new ServicesprofileService();
        // MSC: Since we use lazy loading for the routing, route.data is not available here yet. Subscribe to an event where this will be filled.
        this.router.events.pipe(filter(event => event instanceof ActivationEnd && event.snapshot.children.length == 0)).subscribe(event => {
            this.routeData = event['snapshot'].data;

            let requiredClaims = this.routeData["requiredClaims"] as Array<string>;
            if (requiredClaims) {
                for (let claim of requiredClaims) {
                    if (!this.services.hasClaim(claim)) {
                        this.router.navigateByUrl('/');
                    }
                }
            }
        });

        return true;
    }

}
