import { Injectable } from '@angular/core';
import En from '../assets/i18n/catalog/en.json';
import Nl from '../assets/i18n/catalog/nl.json';
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";

@Injectable({
    providedIn: 'root'
})
export class ServicesCatalogService {

    public Catalog;

    constructor(public translate: TranslateService) {
        this.setCatalog(translate.currentLang);

        translate.onLangChange.subscribe((event: LangChangeEvent) => {
            console.log("Language changed to: " + translate.currentLang);
            this.setCatalog(translate.currentLang);
        });
    }

    private setCatalog(language) {
        this.Catalog = language === "nl" ? Nl : En;
    }
}
