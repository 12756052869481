import { Component, OnInit, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../auth/auth.service';
import { ServicesprofileService } from "../../../servicesprofile.service";
import { LastvisitedService, LastVisitedPage } from '../../../lastvisited.service';
import { URLBuilderService, PageType } from "../../../urlbuilder.service";
import { ServicesCatalogService } from '../../../servicescatalog.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { TaxModelService } from '../../../taxmodel.service';

declare let mLayout: any;
@Component({
    selector: "app-header-nav",
    templateUrl: "./header-nav.component.html",
    styleUrls: ['./header-nav.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class HeaderNavComponent implements OnInit, AfterViewInit {
    public lastVisited: LastvisitedService = new LastvisitedService();
    public catalog: ServicesCatalogService = new ServicesCatalogService(this.translate);
    public pageType = PageType;
    public categories: any;

    public displaySubmenu = false;
    public submenuCategory: any;
    profile: any;

    constructor(
        private router: Router,
        public auth: AuthService,
        public services: ServicesprofileService,
        public urlBuilder: URLBuilderService,
        public translate: TranslateService,
        private taxModelSerivce: TaxModelService) {
        translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.categories = this.catalog.Catalog.categories;
        });
    }

    ngOnInit() {
        this.categories = this.catalog.Catalog.categories;
        if (this.auth.userProfile) {
            this.profile = this.auth.userProfile;
        } else {
            this.auth.getProfile((err, profile) => {
                this.profile = profile;
                this.services = new ServicesprofileService();
            })
        }

        this.urlBuilder = new URLBuilderService();
    }

    ngAfterViewInit() {
        mLayout.initHeader();
    }

    logout() {
        this.auth.logout();
    }

    switchLang(lang: string) {
        this.translate.use(lang);
        localStorage.setItem("language", lang);
    }

    public untoggleAll() {
        this.displaySubmenu = false;
    }

    public toggleSubmenu(menuItem: string) {
        this.untoggleAll();

        this.submenuCategory = this.categories.filter(cat => {
            if (cat.name === menuItem) return true;
            return false;
        });
        this.submenuCategory = this.submenuCategory[0];
        this.displaySubmenu = true;
    }

    public navToExternalProduct(pageType: PageType, serviceType: string) {
        this.lastVisited.addToRecentlyVisited(pageType, serviceType);

        let page = new LastVisitedPage(pageType, serviceType);
        if (page.external) {
            this.taxModelSerivce.navigateToTaxModelService(serviceType);
        }
        else {
            this.router.navigate([page.url, serviceType]);
        }
    }

    public navToServiceDescription(serviceCategory: string) {
        document.location.href = '/services?cat=' + serviceCategory;
    }
}