<!-- begin::Page loader -->
<div class="app-loading-ng">
    <div class="app-loading-container">
        <img src="./assets/app/media/img/Outline_WHITE_100px.png" />
        <h2>Grant Thornton</h2>
    </div>
</div>
<!-- end::Page loader -->
<!-- begin:: Page -->
<router-outlet></router-outlet>
<!-- end:: Page -->
<!-- <app-sticky></app-sticky> --> <!-- Moved to default.component-->
<!--begin::Base Scripts -->
<!--end::Base Scripts -->
<!--begin::Page Vendors -->
<!--end::Page Vendors -->
<!--begin::Page Snippets -->
<!--end::Page Snippets -->