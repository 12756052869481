import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class StartupProvider {

    constructor(private http: HttpClient) { }

    setAppProperties() {
        return new Promise((resolve, reject) => {
            const subdomain = location.host.split(".")[0];
            this.http
                .get(location.protocol + '//' + location.host + "/client-assets/config.json").subscribe(response => {
                    sessionStorage.setItem("clientId", response["clientId"]);
                    sessionStorage.setItem("audience", response["audience"]);
                    sessionStorage.setItem("authDomain", response["authDomain"]);
                    sessionStorage.setItem("baseUrl", response["baseUrl"]);
                    if (response["connection"]) {
                        sessionStorage.setItem("connection", response["connection"]);
                        console.log("connection from config")
                    } else {
                        sessionStorage.setItem("connection", subdomain);
                        console.log("connection from domain")
                    }
                    resolve(true);
                })

        })
    }

}

export function StartupProviderFactory(provider: StartupProvider) {
    return () => provider.setAppProperties();
}
