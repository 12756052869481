<!-- begin::Footer -->
<footer class="m-grid__item		m-footer" appunwraptag="">
	<div class="m-container m-container--fluid m-container--full-height m-page__container">
		<div class="m-stack m-stack--flex-tablet-and-mobile m-stack--ver m-stack--desktop">
			<div class="m-stack__item m-stack__item--right m-stack__item--middle m-stack__item--first">
				<ul class="m-footer__nav m-nav m-nav--inline m--pull-right">
					<li class="m-nav__item">
                        <img src="./assets/app/media/img/GTlogo-CMYK.PNG" class="gt-logo-overview" />
					</li>
				</ul>
				<ul class="m-footer__nav m-nav m-nav--inline m--pull-left">
					<li class="m-nav__item">
                        <a href="https://www.grantthornton.nl/" target="_blank" class="m-nav__link">
                            <span class="m-nav__link-text">© 2020 Grant Thornton</span>
						</a>
					</li>
				</ul>
			</div>
		</div>
	</div>
</footer>
<!-- end::Footer -->