<!-- BEGIN: Header -->
<header id="m_header" class="m-grid__item    m-header" m-minimize-offset="200" m-minimize-mobile-offset="200"
    appunwraptag="" (mouseleave)="untoggleAll()">
    <div class="m-container m-container--fluid m-container--full-height">
        <div class="m-stack m-stack--ver m-stack--desktop">
            <!-- BEGIN: Brand -->
            <div class="m-stack__item m-brand sonar_logo_width">
                <div class="m-stack m-stack--ver m-stack--general">
                    <div class="m-stack__item m-stack__item--middle sonar_logo">
                        <a routerLink="/overview" class="m-brand__logo-wrapper">
                            <img alt="" src="./assets/app/media/img/Sonar.png" />
                        </a>
                    </div>
                </div>
            </div>
            <!-- END: Brand -->
            <div class="m-stack__item m-stack__item--fluid m-header-head m-brand" id="m_header_nav">
                <!-- BEGIN: Horizontal Menu -->
                <button class="m-aside-header-menu-mobile-close  m-aside-header-menu-mobile-close--skin-dark"
                    id="m_aside_header_menu_mobile_close_btn">
                    <i class="la la-close"></i>
                </button>

                <div id="m_header_menu"
                    class="m-header-menu m-aside-header-menu-mobile m-aside-header-menu-mobile--offcanvas m-header-menu--skin-light m-header-menu--submenu-skin-light m-aside-header-menu-mobile--skin-dark m-aside-header-menu-mobile--submenu-skin-dark">
                    <ul class="nav m-tabs-line m-tabs-line--2x m-tabs-line--success" role="tablist">
                        <li class="nav-item m-tabs__item">
                            <a class="nav-link m-tabs__link active show" data-toggle="tab" role="tab"
                                aria-selected="true" routerLink="/overview" (mouseover)="untoggleAll()">{{"Header.MyOverview" | translate}}</a>
                        </li>
                        <li class="nav-item m-tabs__item" *ngFor="let categorie of categories">
                            <a class="nav-link m-tabs__link" data-toggle="tab" role="tab" href="#"
                                (mouseover)="toggleSubmenu(categorie.name)">{{categorie.name}}</a>
                        </li>
                    </ul>
                </div>

                <!-- END: Horizontal Menu -->
                <!-- BEGIN: Topbar -->
                <div id="m_header_topbar" class="m-topbar  m-stack m-stack--ver m-stack--general m-stack--fluid">
                    <div class="m-stack__item m-topbar__nav-wrapper">
                        <ul class="m-topbar__nav m-nav m-nav--inline">
                            <li class="m-nav__item">
                                <ul class="nav m-tabs-line m-language-switch">
                                    <li *ngFor="let language of translate.getLangs()" class="nav-item m-nav__item">
                                        <input type="radio" name="headerLanguage" value="{{language}}}" id="headerLanguage-{{language}}" (change)="switchLang(language)" [checked]="language === translate.currentLang">
                                        <label for="headerLanguage-{{language}}" >{{ language }}</label>
                                    </li>
                                </ul>
                            </li>
                            <li class="m-nav__item m-topbar__user-profile m-topbar__user-profile--img  m-dropdown m-dropdown--medium m-dropdown--arrow m-dropdown--header-bg-fill m-dropdown--align-right m-dropdown--mobile-full-width m-dropdown--skin-light"
                                m-dropdown-toggle="click">
                                <a href="#" class="m-nav__link m-dropdown__toggle m-topbar-user-profile__text">
                                    <span class="m-topbar__username">{{"Header.Welcome" | translate}}, {{ profile?.name }}<i
                                            class="m-topbar__arrow m-menu__hor-arrow la la-angle-down"></i></span>
                                </a>
                                <div class="m-dropdown__wrapper">
                                    <span
                                        class="m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust"></span>
                                    <div class="m-dropdown__inner">
                                        <div class="m-dropdown__header m--align-center">
                                            <div class="m-card-user m-card-user--skin-dark">
                                                <div class="m-card-user__pic">
                                                    <img [src]="profile?.picture" class="m--img-rounded m--marginless"
                                                        alt="" />
                                                </div>
                                                <div class="m-card-user__details">
                                                    <span class="m-card-user__name m--font-weight-500">{{profile?.nickname}}
                                                    </span>
                                                    <a href="" class="m-card-user__email m--font-weight-300 m-link">{{profile?.name}}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="m-dropdown__body">
                                            <div class="m-dropdown__content">
                                                <ul class="m-nav m-nav--skin-light">
                                                    <li class="m-nav__separator m-nav__separator--fit"> </li>
                                                    <li class="m-nav__item">
                                                        <button
                                                            class="btn btn-secondary m-btn m-btn--custom m-btn--label-brand m-btn--bolder"
                                                            (click)="logout()">{{"Header.Logout" | translate}}</button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>                            
                        </ul>
                    </div>
                </div>
                <!-- END: Topbar -->
            </div>
        </div>
    </div>

    <!--Submenu-->
    <div class="m-container m-container--fluid m-container--full-height" [hidden]="!displaySubmenu">
        <div class="m-stack m-stack--ver m-stack--desktop m-stack--submenu" *ngIf="submenuCategory">
            <div class="m-stack__item m-brand" style="background-color: transparent;">
            </div>
            <div class="m-submenu-central">
                <div class="m-portlet--m-portlet-submenu">
                    <div
                        class="m-portlet__head m-portlet__head-brand m-portlet__head--fit m-portlet-clean m-portlet__head--noborder">
                        <div class="m-portlet__head-tools">
                            <ul class="m-submenu-central nav nav-tabs m-tabs-line m-tabs-line--success m-tabs-line--2x"
                                role="tablist">
                                <li class="nav-item m-tabs__item">
                                    <a class="m-submenu-central nav-link m-tabs__link active" data-toggle="tab"
                                        role="tab">
                                        {{submenuCategory.name}}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="m-portlet__body ">
                        <div class="m-portlet__body-inner m-submenu-central-content">
                            <div class="row">
                                <div class="m-submenu-service-wrapper" *ngFor="let service of submenuCategory.services">
                                    <a (click)="navToExternalProduct(service.pageType, service.id)" href class="m-link" *ngIf="services.hasClaim(service.role)">
                                        <div class="m-submenu-service">
                                            <img src="./assets/app/media/img/thought_gear_purple_8918_0.png" class="svg-rocket" />
                                            <span class="gt-link-highlight submenu">{{service.title}}</span>
                                            <span class="submenu-pointer">➔</span>
                                        </div>
                                    </a>

                                    <a class="unavailable-link" (click)="navToServiceDescription(submenuCategory.name)"  *ngIf="!services.hasClaim(service.role)">
                                        <div class="m-submenu-service unavailable">
                                            <img src="./assets/app/media/img/target_purple_8915_0.png" class="svg-rocket unavailable" />
                                            <span class="gt-link-highlight submenu">{{service.title}}</span>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--End Submenu-->
</header>
<!-- END: Header -->