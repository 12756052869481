import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { URLBuilderService, PageType } from './urlbuilder.service';

@Injectable({
    providedIn: 'root'
})
export class LastvisitedService {
    constructor() { }

    private getRecentlyVisitedFromStorage(): Array<string> {
        var recentPagesJson = localStorage.getItem('recentPages');
        let recentPages;
        if (!recentPagesJson) {
            recentPages = [];
        } else {
            recentPages = JSON.parse(recentPagesJson);
        }


        return recentPages;
    }

    public getRecentlyVisited(): Array<LastVisitedPage> {
        let recentPages = this.getRecentlyVisitedFromStorage();
        var pages: Array<LastVisitedPage> = [];

        for (let recentPage of recentPages) {
            var page: PageType = <PageType>recentPage;

            pages.push(new LastVisitedPage(page, recentPage));
        }

        return pages;
    }

    public addToRecentlyVisited(page: PageType, serviceType: string) {
        let recentPages = this.getRecentlyVisitedFromStorage();
        var index = recentPages.indexOf(serviceType);
        if (index > -1) {
            recentPages.splice(index, 1);
        }

        if (recentPages.length > 6) {
            recentPages.splice(5);
        }
        if (serviceType != "Dashboard" && serviceType != "Overview")
            recentPages.unshift(serviceType);

        localStorage.setItem("recentPages", JSON.stringify(recentPages));
    }
}

export class LastVisitedPage {
    public url: string;
    public external: boolean = false;
    public serviceType: string;
    private urlBuilderService: URLBuilderService;

    constructor(page: PageType, serviceType: string) {
        this.urlBuilderService = new URLBuilderService();

        this.serviceType = serviceType;

        this.url = this.urlBuilderService.getUrl(page);

        switch (page) {
            case PageType.TaxProof: {
                this.external = true;
                break;
            }
            case PageType.TaxBenchmark: {
                this.external = true;
                break;
            }
            case PageType.ExtendedFlow: {
                this.external = true;
                break;
            }
            default: {
                break;
            }
        }
    }
}