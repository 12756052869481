import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { Helpers } from "./helpers";
import { AuthService } from './auth/auth.service';
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: 'body',
    templateUrl: './app.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
    title = 'app';
    globalBodyClass = 'm-page--loading-non-block m-page--fluid m--skin- m-content--skin-light2 m-header--fixed m-header--fixed-mobile m-aside-left--enabled m-aside-left--skin-dark m-aside-left--fixed m-aside-left--offcanvas m-footer--push m-aside--offcanvas-default';
    constructor(private _router: Router, private authService: AuthService, public translate: TranslateService) {
        this.setLanguage(translate);
    }

    ngOnInit() {
        this.authService.handleAuthentication();
        this._router.events.subscribe((route) => {
            if (route instanceof NavigationStart) {
                Helpers.setLoading(true);
                Helpers.bodyClass(this.globalBodyClass);
            }
            if (route instanceof NavigationEnd) {
                Helpers.setLoading(false);
            }
        });
    }

    setLanguage(translate) {
        translate.addLangs(['en', 'nl']);

        let language = localStorage.getItem('language');
        if (language === null)
            language = translate.getBrowserLang();

        if (translate.getLangs().indexOf(language) === -1)
            language = 'en';

        translate.setDefaultLang(language);
        translate.currentLang = language;
    }
}