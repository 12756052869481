import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class URLBuilderService {
    constructor() { }

    public getUrl(pageType: PageType): string {
        switch (pageType) {
            case (PageType.Overview): {
                return "/overview";
            }
            case (PageType.Tax): {
                return "/";
            }
            case (PageType.Accountancy): {
                return "/";
            }
            case (PageType.Finance): {
                return "/";
            }
            case (PageType.IT): {
                return "/";
            }
            case (PageType.Services): {
                return "/services";
            }
            case (PageType.VATCheck): {
                return "/dashboard";
            }
            case (PageType.WKR): {
                return "/dashboard";
            }
            case (PageType.CBCR): {
                return "/dashboard";
            }
            case (PageType.wachtwoordanalyse): {
                return "/dashboard";
            }
            case (PageType.ADcheck): {
                return "/dashboard";
            }
            case (PageType.GDPR): {
                return "/dashboard";
            }
            case (PageType.MDQA): {
                return "/dashboard";
            }
            case (PageType.Financialaudit): {
                return "/dashboard";
            }
            case (PageType.Duplicateinvoices): {
                return "/dashboard";
            }
            case (PageType.WCM): {
                return "/dashboard";
            }
            case (PageType.ERMQ): {
                return "/dashboard";
            }
            case (PageType.Dashboard): {
                return "/dashboard";
            }
            case (PageType.TaxProof): {
                return "https://taxmodel-sandbox.tax-model.net/TaxCore/Login/SSOLogin?username=deploy@tax-model.com";
            }
            case (PageType.TaxBenchmark): {
                return "https://taxmodel-sandbox.tax-model.net/TaxCore/Login/SSOLogin?username=deploy@tax-model.com";
            }
            case (PageType.ExtendedFlow): {
                return "https://taxmodel-sandbox.tax-model.net/TaxCore/Login/SSOLogin?username=deploy@tax-model.com";
            }
            default: {
                return "/dashboard";
            }
        }
    }
}

export enum PageType {
    Overview = "Overview",
    Tax = "Tax",
    Accountancy = "Accountancy",
    Finance = "Finance",
    IT = "IT",
    Services = "Services",
    VATCheck = "vat-check",
    TaxProof = "taxproof",
    TaxBenchmark = "taxbenchmark",
    ExtendedFlow = "extendedflow",
    Dashboard = "Dashboard",
    WKR = "wkr",
    CBCR = "cbcr",
    wachtwoordanalyse = "wachtwoordanalyse",
    ADcheck = "ad-check",
    GDPR = "gdpr",
    MDQA = "mdqa",
    Financialaudit = "financialaudit",
    Duplicateinvoices = "duplicateinvoices",
    WCM = "wcm",
    ERMQ = "ermq"
}