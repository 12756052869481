import { Injectable, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import * as auth0 from "auth0-js";
import { environment } from "../../environments/environment";

@Injectable()
export class AuthService {

    auth0 = new auth0.WebAuth({
        clientID: sessionStorage.getItem("clientId"),
        domain: sessionStorage.getItem('authDomain'),
        responseType: "token id_token",
        audience: sessionStorage.getItem('audience'),
        redirectUri:
            location.protocol + "//" + location.host + "/identity-callback",
        scope: "openid profile"
    });

    public userProfile: any;
    private permissionsClaim: string;
    returnUrl: any;

    constructor(public router: Router) {
        this.permissionsClaim = "https://www.vitas.nl/permissions";
    }

    public login(): void {
        this.auth0.authorize({
            connection: sessionStorage.getItem("connection")
        });
    }

    // isAuthenticated(){
    //     return new Promise((resolve, reject) => {
    //         resolve(true)
    //     })
    // }

    public handleAuthentication(): void {
        this.auth0.parseHash((err, authResult) => {
            if (authResult && authResult.accessToken && authResult.idToken) {
                this.setSession(authResult);
                let url = sessionStorage.getItem('redirect');
                this.router.navigateByUrl(url);
            } else if (err) {
                this.router.navigate(["/overview"]);
                console.log(err);
                alert(`Error: ${err.error}. Check the console for further details.`);
            }
        });
    }

    //Docs: https://auth0.com/docs/quickstart/spa/angular2/02-user-profile
    public getProfile(cb): void {
        const accessToken = sessionStorage.getItem("access_token");
        if (!accessToken) {
            throw new Error("Access Token must exist to fetch profile");
        }

        const self = this;
        this.auth0.client.userInfo(accessToken, (err, profile) => {
            if (profile) {
                self.userProfile = profile;
                sessionStorage.setItem("permissions", profile[this.permissionsClaim]);
            }
            cb(err, profile);
        });
    }

    private setSession(authResult): void {
        // Set the time that the access token will expire at
        const expiresAt = JSON.stringify(
            authResult.expiresIn * 1000 + new Date().getTime()
        );
        sessionStorage.setItem("access_token", authResult.accessToken);
        sessionStorage.setItem("id_token", authResult.idToken);
        sessionStorage.setItem("expires_at", expiresAt);
    }

    public logout(): void {
        // Remove tokens and expiry time from localStorage
        sessionStorage.removeItem("access_token");
        sessionStorage.removeItem("id_token");
        sessionStorage.removeItem("expires_at");
        const URL = `${window.location.protocol}//${window.location.host}`;
        // Go back to the home route
        window.location.href = `https://${sessionStorage.getItem('authDomain')
            }/v2/logout?client_id=${sessionStorage.getItem(
                "clientId"
            )}&returnTo=${encodeURI(URL)}`;
    }

    public isAuthenticated(): boolean {
        // Check whether the current time is past the
        // access token's expiry time, session should be at least valid for 1 hour.
        const expiresAt = JSON.parse(sessionStorage.getItem("expires_at") || "{}");
        return (new Date().getTime() + (1 * 60 * 60 * 1000)) < expiresAt;
    }
}
